import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { Layout, Hero, Fade } from '~/components';
import { Headline, Icon } from '~/elements';
import jakub from '~/images/jakub.jpg';
import jakub2x from '~/images/jakub@2x.jpg';
import aaron from '~/images/aaron.jpg';
import aaron2x from '~/images/aaron@2x.jpg';
import nisarg from '~/images/nisarg.jpg';
import nisarg2x from '~/images/nisarg@2x.jpg';
import { mq } from '~/Theme';

const About = styled.div`
  background-color: ${({ theme }) => theme.secondary.blendToBackground(125)};
  border-radius: 20px;
  padding: 60px;
  margin: 120px 0;

  ${mq.lessThan('lg')`
    margin: 100px 0;
    padding: 48px;
  `}

  ${mq.lessThan('md')`
    margin: 80px 0;
    padding: 40px;
  `}

  ${mq.lessThan('sm')`
    margin: 60px 0;
    padding: 32px;
  `}
`;

const Grid = styled.div`
  margin-top: 36px;
  display: grid;
  grid-gap: 60px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  ${mq.lessThan('md')`
    margin-top: 32px;
  `}

  ${mq.lessThan('sm')`
    margin-top: 20px;
    grid-gap: 32px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  `}
`;

const Box = styled.div``;

const Avatar = styled.img`
  border-radius: 12px;
  display: block;
`;

const Name = styled(Headline)`
  margin-top: 24px;
`;

const Description = styled.p`
  margin: 8px 0 16px 0;
  color: ${({ theme }) => theme.secondary.background};
`;

const SocialList = styled.div`
  display: flex;

  & > * {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const Social = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 14px;
  transition: background-color 0.15s;

  background-color: ${({ theme }) => theme.secondary.blendToBackground(250)};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02), inset 0px -1px 2px rgba(0, 0, 0, 0.03);

  &:hover {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(275)};
  }

  &:active {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(300)};
  }

  svg {
    --icon-color: ${({ theme }) => theme.foreground};
  }
`;

const AboutPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Layout>
      <Helmet>
        <title>About - {data.site.siteMetadata.title}</title>
      </Helmet>
      <Hero
        headline={
          <>
            <span>We’re building</span>
            <br />
            to-do list you’ll enjoy using
          </>
        }
        text={
          <>
            Our mission with Dona is to solve current problems with to-do list apps by a more humane design approach and
            a completely natural user experience.{' '}
            <span>
              We want to bring satisfaction to every day use of to-do lists and not feeling it like a chore anymore.
            </span>
          </>
        }
      />
      <Fade>
        <About>
          <Headline type="h2">Team</Headline>
          <Grid>
            <Box>
              <Avatar src={jakub} srcSet={`${jakub2x} 2x`} />
              <Name type="h4">Jakub Antalik</Name>
              <Description>Product designer with passion for motion design and interactive experiences.</Description>
              <SocialList>
                <Social href="https://twitter.com/jakubantalik" target="_blank" rel="noreferrer">
                  <Icon.Twitter />
                </Social>
                <Social href="https://dribbble.com/antalik" target="_blank" rel="noreferrer">
                  <Icon.Dribbble />
                </Social>
              </SocialList>
            </Box>
            <Box>
              <Avatar src={aaron} srcSet={`${aaron2x} 2x`} />
              <Name type="h4">Aaron Iker</Name>
              <Description>
                Creative developer who loves to bring more fun to the web &amp; entertain people by creating playful
                micro interactions.
              </Description>
              <SocialList>
                <Social href="https://twitter.com/aaroniker_me" target="_blank" rel="noreferrer">
                  <Icon.Twitter />
                </Social>
                <Social href="https://dribbble.com/ai" target="_blank" rel="noreferrer">
                  <Icon.Dribbble />
                </Social>
                <Social href="https://www.instagram.com/aaroniker" target="_blank" rel="noreferrer">
                  <Icon.Instagram />
                </Social>
              </SocialList>
            </Box>
            <Box>
              <Avatar src={nisarg} srcSet={`${nisarg2x} 2x`} />
              <Name type="h4">Nisarg Patel</Name>
              <Description>
                Backend developer focused on bringing innovative solutions and performance to interesting products.
              </Description>
              <SocialList>
                <Social href="https://twitter.com/nisargptel" target="_blank" rel="noreferrer">
                  <Icon.Twitter />
                </Social>
                <Social href="https://nisarg.io" target="_blank" rel="noreferrer">
                  <Icon.Link />
                </Social>
              </SocialList>
            </Box>
          </Grid>
        </About>
      </Fade>
    </Layout>
  );
};

export default AboutPage;
